import React, { useState, useEffect, useRef } from 'react';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import TextBox from '../../../components/Input/TextBox';
import {
  CancelButton,
  Button,
  Input,
} from '../../../components/Modals/styles';
import GroupsService from '../../../services/AdminServices/GroupsService';
import Config from '../../../config';
import MultiSelect from '../../../components/Input/MultiSelect';
import GiggerService from '../../../services/AdminServices/GiggersService';
import ModalLoader from '../../../components/Loader/ModalLoader';

const CreateGroupModal = ({
  onModalClose, handleToast,
}) => {
  const groupsService = useRef(new GroupsService(Config.apiBase));
  const giggerService = useRef(new GiggerService(Config.apiBase));
  const [groupName, setGroupname] = useState('');
  const [giggers, setGiggers] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const closeModal = useRef(null);
  const [giggerList, setGiggersList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchGiggers = async () => {
      setLoading(true);
      const queryParams = [
        ['pageSize', 0],
      ];
      try {
        const response = await giggerService.current.getGiggerList(queryParams);

        setGiggersList(response?.map((o) => {
          const { full_name: fullName, _id } = o;
          return ({ label: fullName, value: _id });
        }));
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };
    fetchGiggers();
  }, []);

  const handleSubmit = async () => {
    setIsDisabled(true);
    setLoading(true);
    const selectedGiggers = giggers?.value?.split(',');
    const payload = {
      name: groupName,
      gigger_id: selectedGiggers,
    };
    try {
      await groupsService.current.createGroup(payload);
      onModalClose();
      handleToast({ type: 'success', message: 'New group created' });
    } catch (error) {
      setLoading(false);
      onModalClose();
      handleToast({ type: 'error', message: 'Failed to create group' });
    }
  };

  const handleChange = (event) => {
    const { value } = event?.target;
    setGroupname(value);
  };

  const handleOnGiggerChange = (name, label, value) => {
    setGiggers({ label, value });
  };

  return (
    <>
      {loading && <ModalLoader />}
      {!loading && (
      <>
        <Input>
          <TextBox
            type="text"
            name="groupName"
            value={groupName}
            label={<FormattedMessage id="label_group_name" defaultMessage="Group name" />}
            onChange={handleChange}
            errorMessage={<FormattedMessage id="error_group_name_message" defaultMessage="Enter group name" />}
            rule={{
              method: validator.isEmpty,
              validWhen: false,
            }}
          />
        </Input>
        <Input>
          <MultiSelect
            forFilter
            showSearch
            className="mb-4"
            label={<FormattedMessage id="label_members" defaultMessage="Members" />}
            placeholder={<FormattedMessage id="choose_members" defaultMessage="Choose members" />}
            name="giggers"
            options={giggerList}
            onChange={handleOnGiggerChange}
            noEmptyError
          />
        </Input>
        <Input className="mt-4">
          <Button
            disabled={isDisabled}
            onClick={handleSubmit}
            data-dismiss="modal"
            aria-label="Close"
          >
            <FormattedMessage id="common_create" defaultMessage="Create" />
          </Button>
          <CancelButton
            data-dismiss="modal"
            aria-label="Close"
            onClick={onModalClose}
            ref={closeModal}
          >
            <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
          </CancelButton>
        </Input>
      </>
      )}
    </>
  );
};

export default CreateGroupModal;
