import React from 'react';
import {
  Section,
  LabelWrapper,
  Label,
  TextDiv,
  MandatoryLabel,
  SelctBorderBox,
  Divison,
  SelectPlaceholder,
  DropDownWrapper,
  DropDownList,
  DropDownListWrapper,
  SelectedValue,
} from './styles';
import { ArrowDown } from '../../styles/Icons';
import CustomCheckbox from './CustomCheckbox';
import TextBox from './TextBox';
import Constants from '../../shared/constants';

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    const { id, options } = this.props;
    const selectedValue = this.handleSelectedData(options);
    this.state = {
      dropDown: false,
      options,
      selectedValue,
      allOptions: options,
      isValid: true,
      showError: false,
      searchString: '',
      showPlusIcon: false,
      initialOptions: [...options],
    };
    this.selectRef = [];
    this.selectRef[id] = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { options: currentOptions = [] } = this.props;
    let { initialOptions } = this.state;
    if (nextProps.options !== currentOptions || nextState.searchString === '') {
      if (initialOptions && initialOptions.length === 0
        && nextProps.options && nextProps.options.length !== 0) {
        initialOptions = [...nextProps.options];
      }
      let { options: nextOptions = [] } = nextProps;
      nextOptions = nextOptions
        ? nextOptions.map((obj) => ({
          ...obj, isSelected: obj.isSelected ? obj.isSelected : false,
        }))
        : [];
      const selectedValue = this.handleSelectedData(nextOptions);
      this.setState({
        options: nextOptions, selectedValue, allOptions: nextOptions, initialOptions,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { options } = this.props;
    const { searchString } = this.state;
    if (prevState.searchString !== searchString && searchString.length === 0) {
      this.onSearchUpdate();
    }
    if (prevProps.options !== options) {
      this.onOptionsUpdate();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  /**
   * Closes dropdown onclicking outside the component
   * @param {event} e captures click event
   */
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    const { dropDown } = this.state;
    const { id } = this.props;
    if (!this.selectRef[id].contains(e.target) && dropDown) {
      this.closeDropDown();
    }
  }

  /**
   * Updates options on change in search value
   */
  onSearchUpdate = () => {
    const { allOptions } = this.state;
    const filteredOptions = this.filterUniqueOptions(allOptions);
    this.setState({ options: filteredOptions, allOptions: filteredOptions });
  };

  /**
   * Rearranges the options based on selected value
   */
  onOptionsUpdate = () => {
    let { options = [] } = this.props;
    const selectedOptions = options.filter((obj) => obj.isSelected === true);
    options = options.filter((obj) => obj.isSelected !== true);
    options = [...selectedOptions, ...options];
    this.setState({
      options,
      allOptions: options,
    });
  };

  /**
   * Removes duplicated options
   * @param {*} options
   */
  filterUniqueOptions = (options) => {
    const map = {};
    return options.filter((option) => {
      const label = option.label.toLowerCase();
      if (!map[label]) {
        map[label] = label;
        return true;
      } return false;
    });
  };

  /**
   * Closes dropdown
   * Rearranges the options based on selected options.
   */
  closeDropDown = () => {
    const { dropDown, initialOptions } = this.state;
    let { allOptions } = this.state;
    const selectedOptions = allOptions.filter((obj) => obj.isSelected === true);
    allOptions = allOptions.filter((obj) => obj.isSelected !== true);
    allOptions = initialOptions.length > 0 && selectedOptions.length === 0
      ? initialOptions.map((obj) => (
        { ...obj, isSelected: false })) : [...selectedOptions, ...allOptions];
    this.setState(
      (prevState) => ({
        dropDown: !prevState.dropDown,
        options: allOptions,
        searchString: '',
        showPlusIcon: false,
        allOptions,
      }),
    );
    if (dropDown) {
      this.validate();
    }
  };

  /**
   * Opens dropdown and rearranges the options based on selected options
   */
  openDropDown =() => {
    const { showPlusIcon = false } = this.props;
    let { allOptions } = this.state;
    const selectedOptions = allOptions.filter((obj) => obj.isSelected === true);
    allOptions = allOptions.filter((obj) => obj.isSelected !== true);
    allOptions = [...selectedOptions, ...allOptions];
    this.setState({
      dropDown: true,
      options: allOptions,
      searchString: '',
      showPlusIcon,
      allOptions,
    });
  }

  /**
   * Closes dropdown when mouse leaves from the component
   */
  closeDropDownOnMouseLeave=() => {
    let { allOptions } = this.state;
    const selectedOptions = allOptions.filter((obj) => obj.isSelected === true);
    allOptions = allOptions.filter((obj) => obj.isSelected !== true);
    allOptions = [...selectedOptions, ...allOptions];
    this.setState({
      dropDown: false,
      options: allOptions,
      searchString: '',
      showPlusIcon: false,
      allOptions,
    });
  }

  /**
 * Marks particular option is selected among all options
 * @param {*} obj
 */
  optionSelected = (obj) => () => {
    const { allOptions } = this.state;
    const { name, onChange } = this.props;

    const allOptionsIndex = allOptions.findIndex(
      (allOption) => allOption.label.toLowerCase() === obj.label.toLowerCase(),
    );

    if (allOptionsIndex >= 0) {
      allOptions[allOptionsIndex].isSelected = !allOptions[allOptionsIndex].isSelected;
    }

    const selectedValue = this.handleSelectedData(allOptions);
    // if (forFilter) {
    const selectedId = allOptions
      .map((option) => (option.isSelected === true ? option.value : ''))
      .filter((o) => o !== '')
      .join(',');

    //   if (onChange) {
    //     onChange(name, selectedValue, selectedId);
    //   }
    // } else
    if (onChange) {
      onChange(name, selectedValue, selectedId);
    }
    this.setState({ selectedValue, allOptions }, this.validate);
  };

  /**
   * @param {*} options
   * @returns a string with all selected options label
   */
  handleSelectedData = (options) => options
    .map((obj) => (obj.isSelected === true ? obj.label : ''))
    .filter((o) => o !== '')
    .join(', ');

  /**
     * validates the input element
     */
  validate = () => {
    const { selectedValue } = this.state;
    const { value, noEmptyError } = this.props;
    const showError = true;
    const isValid = selectedValue !== '' || value !== '' || noEmptyError;

    this.setState({ showError, isValid });
  };

  /**
   * search happens among the options.
   * @param {event} e
   */
  handleSearch = (e) => {
    let { value } = e.target;
    value = value.replace(value.substr(0, 1), value.substr(0, 1).toUpperCase());
    let options;
    const { allOptions } = this.state;
    if (value === '') {
      options = allOptions;
    } else {
      options = allOptions.filter((column) => (
        column.label.toLowerCase().includes(value.toLowerCase())));
    }

    this.setState({ options, searchString: value, showPlusIcon: options.length < 1 });
  };

  handleKeypress = (e) => {
    const { onChange, name } = this.props;

    let { value } = e.target;
    // Converts first letter to uppercase
    value = value.replace(value.substr(0, 1), value.substr(0, 1).toUpperCase());

    // on pressing enter the entered value is verified among the existing options.
    // If yes, the option is checked as selected
    // If no, a new option with the search value is added to the existing options

    if (e.key === 'Enter' && value.trim().length) {
      let options;
      let { allOptions } = this.state;

      if (value !== '') {
        options = allOptions.filter((column) => (
          column.label.toLowerCase().includes(value.toLowerCase())));
        const doValueExists = allOptions.find((o) => o.label.toLowerCase() === value.toLowerCase());

        if (options.length > 0 && doValueExists) {
          allOptions = allOptions.map((o) => {
            if (o.label.toLowerCase() === value.toLowerCase()) {
              return { ...o, isSelected: true };
            }
            return o;
          });
        } else {
          allOptions.push({
            label: value.trim(),
            value: value.trim(),
            isSelected: true,
            status: Constants.status.PENDING,
          });
        }
      }
      const filteredOptions = this.filterUniqueOptions(allOptions);
      const selectedValue = this.handleSelectedData(allOptions);
      this.setState(
        {
          allOptions: filteredOptions,
          options: filteredOptions,
          searchString: '',
          selectedValue,
          showPlusIcon: false,
        },
        () => {
          // this.optionSelected(newOption);
          this.validate();
        },
      );
      if (onChange) onChange(name, selectedValue);
    }
  };

  /**
   * Adds new option on pressing enter in search element.
   */
  handleAddNewOption = () => {
    const { searchString } = this.state;
    this.handleKeypress({ target: { value: searchString }, key: 'Enter' });
  };

  render() {
    const {
      label,
      id,
      disabled,
      mandatory,
      placeholder,
      classname,
      existError,
      value,
      errorMessage,
      existErrorMessage,
      forFilter,
      className,
      showSearch,
      showSearchAndAdd,
      position,
      width,
      maxHeight,
    } = this.props;
    const {
      dropDown, showError, isValid, searchString, options, showPlusIcon, allOptions,
    } = this.state;
    const selectedValue = allOptions
      .map((obj) => (obj.isSelected === true ? obj.label : ''))
      .filter((o) => o !== '')
      .join(', ');

    const displayError = ((showError && !isValid) || existError || classname);

    return (
      <Section
        width={width}
        ref={(selectRef) => {
          this.selectRef[id] = selectRef;
        }}
        position="relative"
        className={className}
      >
        {label && (
          <LabelWrapper>
            <Label forFilter={forFilter} mandatory={mandatory}>
              {label || ''}
            </Label>
            {mandatory && <MandatoryLabel mandatory={mandatory}>{mandatory}</MandatoryLabel>}
          </LabelWrapper>
        )}

        <SelctBorderBox
          className={`${displayError ? 'border-red' : disabled ? 'disableOption' : ''} row m-0 p-0`}
        >
          <Divison
            className="col p-0"
            onClick={this.closeDropDown}
            role="button"
          >
            {selectedValue ? (
              <SelectedValue>{selectedValue}</SelectedValue>
            ) : value ? (
              <SelectedValue>{typeof value === 'object' ? value?.join(', ') : value}</SelectedValue>
            ) : placeholder ? (
              <SelectPlaceholder>
                {placeholder}
                {''}
              </SelectPlaceholder>
            ) : (
              ''
            )}
          </Divison>
          <DropDownWrapper
            className="col-2 p-0 mr-3 justify-content-end"
            onClick={this.closeDropDown}
            role="button"
          >
            <ArrowDown width="20px" className={dropDown ? 'rotate-180' : ''} />
          </DropDownWrapper>
        </SelctBorderBox>
        {dropDown && !disabled && options && (
          <DropDownListWrapper className={position ? 'position-relative' : 'position-absolute'}>
            {showSearchAndAdd && (
              <TextBox
                type="text"
                name="search"
                disabletickMark
                showPlusIcon={showPlusIcon}
                onAddNewOption={this.handleAddNewOption}
                value={searchString}
                searchClassname="fs-12"
                placeholder={Constants.language.select_search_or_add}
                onChange={this.handleSearch}
                onKeyPress={this.handleKeypress}
              />
            )}
            {showSearch && (
              <TextBox
                type="text"
                name="search"
                value={searchString}
                searchClassname="fs-12"
                placeholder={Constants.language.common_search}
                onChange={this.handleSearch}
                disabletickMark
              />
            )}
            {options.length > 0 && (
              <DropDownList className="card" multiselect maxHeight={maxHeight}>
                {options.map((obj) => (

                  <CustomCheckbox
                    key={obj.value}
                    type="checkbox"
                    label={obj.label}
                    onClick={this.optionSelected(obj)}
                    isSelected={obj.isSelected}
                  />

                ))}
              </DropDownList>
            )}
          </DropDownListWrapper>
        )}
        {existError && isValid && (
          <TextDiv>
            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
            <span>{existErrorMessage}</span>
          </TextDiv>
        )}
        {showError && !isValid && (
          <TextDiv>
            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
            <span>{errorMessage}</span>
          </TextDiv>
        )}
      </Section>
    );
  }
}
export default MultiSelect;
