import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory } from 'react-router';
import Header from '../../../components/Header/Header';
import Constants from '../../../shared/constants';
import {
  FlexContainer,
  Container,
  UsersListWrapper,
  Border,
  TableHeader,
  TableValue,
  TableData,
  Wrapper,
  OptionButton,
} from '../users/styles';
import LoadingCard from '../users/LoadingCard';
import { Card, Span, Div } from '../../../components/Popover/Style';
import Modal from './Modal';
import Pagination from '../../../components/Pagination/Pagination';
import GroupsService from '../../../services/AdminServices/GroupsService';
import Config from '../../../config';
import AuthService from '../../../services/AuthService';
import { WhiteCard } from '../../gigger/styles';
import { WhiteButton } from '../../customer/styles';
import { MainContext } from '../../../components/Toast/Toast';
import DeleteGroupModal from './DeleteGroupModal';
import CreateGroupModal from './CreateGroupModal';
import { Line } from '../giggers/styles';

const Groups = () => {
  const authService = useRef(new AuthService(Config.apiBase));
  const groupsService = useRef(new GroupsService(Config.apiBase));
  const location = useLocation();
  const history = useHistory();

  const loggedIn = authService.current.isAuthenticated();
  const [openColumn, setOpenColumn] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [groupDetails, setGroupDetails] = useState({});
  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: 1,
    pageSize: 15,
    pages: 1,
  });
  const [toastData, setToastData] = useState({});
  const [showToast, setShowToast] = useState(false);
  const filterOptions = useRef([]);
  const openElement = useRef(null);

  const handleOptions = (index, groupdata) => {
    setOpenColumn((prev) => {
      const isSameColumn = prev === index;
      if (!isSameColumn) {
        if (filterOptions?.current?.[index]) {
          filterOptions.current[index].focus();
        }
      }
      return isSameColumn ? '' : index;
    });
    setGroupDetails(groupdata);
  };

  const toFocusOnDiv = () => {
    document.getElementById('clickable-component').focus();
  };

  const fetchGroups = async (searchInput = '') => {
    setLoading(true);
    const queryParams = [
      ['pageNumber', searchInput ? 1 : meta.pageNumber],
      ['pageSize', meta.pageSize],
    ];
    try {
      const response = await groupsService.current.getGroupsList(queryParams);
      const {
        per_page: perPage, current_page: currentPage, total, last_page: lastPage,
      } = response.meta;
      setData(response.data);
      setMeta((prev) => ({
        ...prev,
        pageSize: perPage,
        pageNumber: currentPage,
        count: total,
        pages: lastPage,
      }));
    } catch (error) {
      setToastData({ type: 'error', message: 'Failed to fetch users' });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      fetchGroups();
    }
  }, [loggedIn, meta.pageNumber, location.key]);

  useEffect(() => {
    if (isCreateGroupModalOpen || isDeleteModalOpen) {
      openElement.current.click();
    }
  }, [isCreateGroupModalOpen, isDeleteModalOpen]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        filterOptions.current[openColumn]
        && !filterOptions.current[openColumn].contains(e.target)
      ) {
        setOpenColumn('');
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [openColumn]);

  const handleGotoPage = (pageNumber) => {
    setMeta((prevMeta) => ({ ...prevMeta, pageNumber }));
  };

  if (!loggedIn) {
    return <Redirect to={Constants.routes.logIn.url} />;
  }

  const handleDisplayOptions = () => {
    setOpenColumn('');
  };

  const handleDeleteGroup = () => {
    if (isDeleteModalOpen) {
      fetchGroups();
    }
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleAddGroup = () => {
    if (isCreateGroupModalOpen) {
      fetchGroups();
    }
    setIsCreateGroupModalOpen(!isCreateGroupModalOpen);
  };

  const redirectToGroupDetails = () => {
    const { id } = groupDetails;
    history.push(`/admin/portal/groups/${id}`);
  };

  const handleToast = (values) => {
    setToastData(values);
    setShowToast(true);
  };

  return (
    <MainContext.Provider value={{ showToast, toastData, onToastClose: () => setShowToast(false) }}>
      <Header
        name={<FormattedMessage id="groups" defaultMessage="Groups" />}
        groups
        handleAddGroup={handleAddGroup}
      />
      <FlexContainer>
        <Container>
          <UsersListWrapper>
            {loading ? (
              <UsersListWrapper>
                <LoadingCard />
              </UsersListWrapper>
            ) : (
              <>
                <Wrapper className="row m-0">
                  <TableData className="col">
                    <TableHeader>
                      <FormattedMessage id="common_name" defaultMessage="Name" />
                    </TableHeader>
                  </TableData>

                  <TableData className="col">
                    <TableHeader>
                      <FormattedMessage id="label_members" defaultMessage="Members" />
                    </TableHeader>
                  </TableData>

                  <TableData className="col-auto">
                    <OptionButton />
                  </TableData>
                </Wrapper>
                {data.length > 0 ? (
                  <>
                    {data.map((obj, index) => (
                      <>
                        <Border />
                        <Wrapper className="row m-0">
                          <TableData className="col text-truncate" title={obj.name}>
                            <Link
                              to={{
                                pathname: `/admin/portal/groups/${obj.id}`,
                                query: { id: data.id },
                              }}
                            >
                              <TableValue>{obj?.name ? (<Line>{obj?.name}</Line>) : '-'}</TableValue>
                            </Link>
                          </TableData>

                          <TableData className="col">
                            <TableValue>{obj?.users?.length}</TableValue>
                          </TableData>

                          <TableData
                            className="col-auto"
                            ref={(el) => {
                              if (el) filterOptions.current[index] = el;
                            }}
                          >
                            <TableValue>
                              <WhiteButton onClick={() => handleOptions(index, obj)}>
                                <img
                                  src={Constants.icons.ThreeDots}
                                  alt="icon"
                                  className="cursor-pointer"
                                />
                              </WhiteButton>
                              {openColumn === index && (
                                <Card
                                  onBlur={handleDisplayOptions}
                                  tabIndex="-1"
                                  id="clickable-component"
                                  onLoad={toFocusOnDiv}
                                >
                                  <>
                                    <Div onClick={redirectToGroupDetails} role="button">
                                      <Span>
                                        <FormattedMessage id="common_details" defaultMessage="Details" />
                                      </Span>
                                    </Div>
                                    <Border />
                                    <Div
                                      role="button"
                                      onClick={handleDeleteGroup}
                                    >
                                      <Span>
                                        <FormattedMessage id="common_delete" defaultMessage="Delete" />
                                      </Span>
                                    </Div>
                                  </>
                                </Card>
                              )}
                            </TableValue>
                          </TableData>
                        </Wrapper>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <Border />
                    <WhiteCard className="my-3">
                      <FormattedMessage id="common_users_found" defaultMessage="No users found" />
                    </WhiteCard>
                  </>
                )}
              </>
            )}
          </UsersListWrapper>
          <a
            href
            data-toggle="modal"
            data-target="#GroupModal"
            data-backdrop="static"
            data-keyboard="false"
            ref={openElement}
          />
          {isDeleteModalOpen && (
            <Modal onModalClose={handleDeleteGroup} header={Constants.language.delete_group}>
              <DeleteGroupModal
                data={groupDetails}
                handleToast={handleToast}
                onModalClose={handleDeleteGroup}
                setLoading={setLoading}
              />
            </Modal>
          )}
          {isCreateGroupModalOpen && (
            <Modal onModalClose={handleAddGroup} header={Constants.language.create_new_group}>
              <CreateGroupModal
                handleToast={handleToast}
                onModalClose={handleAddGroup}
              />
            </Modal>
          )}
          {!loading && meta.pages > 1 && (
          <UsersListWrapper style={{ 'background-color': 'transparent' }}>
            <Pagination cursor={meta} gotoPage={handleGotoPage} />
          </UsersListWrapper>
          )}
        </Container>
      </FlexContainer>
    </MainContext.Provider>
  );
};

export default Groups;
